import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Menü | Trais lo Wenda Sports Pub Menüsü
			</title>
			<meta name={"description"} content={"Trais lo Wenda'da menümüz, tüm taraftarlara hitap eden çeşitli lezzetler sunarak spor izleme deneyiminizi geliştirmek için hazırlanmıştır."} />
			<meta property={"og:title"} content={"Menü | Trais lo Wenda Sports Pub Menüsü"} />
			<meta property={"og:description"} content={"Trais lo Wenda'da menümüz, tüm taraftarlara hitap eden çeşitli lezzetler sunarak spor izleme deneyiminizi geliştirmek için hazırlanmıştır."} />
			<meta property={"og:image"} content={"https://traislowenda.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://traislowenda.com/img/icon.jpeg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://traislowenda.com/img/icon.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://traislowenda.com/img/icon.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://traislowenda.com/img/icon.jpeg"} />
			<link rel={"apple-touch-startup-image"} href={"https://traislowenda.com/img/icon.jpeg"} />
			<meta name={"msapplication-TileImage"} content={"https://traislowenda.com/img/icon.jpeg"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Başlangıç Kadromuzu Keşfedin
				</Text>
				<Text font="--lead" color="--darkL2" max-width="600px">
					Trais lo Wenda'da menümüz, tüm taraftarlara hitap eden çeşitli lezzetler sunarak spor izleme deneyiminizi geliştirmek için hazırlanmıştır.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://traislowenda.com/img/5.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Maç Günü Yemekleri
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Şampiyon Tavuk Tender'lar: Çıtır çıtır, altın rengi ve seçtiğiniz daldırma soslarıyla servis edilir - maçı izlerken paylaşmak için mükemmeldir.
							<br />
							<br />
							Zafer Sebze Tabağı: Taze ve renkli mevsim sebzeleri, lezzetli humus sosu ile servis edilir.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://traislowenda.com/img/6.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Ana Etkinlik Yemekleri
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							The Sideline Burger: Sulu, el yapımı dana köftesi, özel sosumuz, marul ve domates ile birlikte kızarmış brioche ekmeği üzerinde.
							<br />
							<br />
							All-Star Sebzeli Burger: Hem vejetaryenler hem de et severler için tatmin edici, tüm klasik burger malzemeleriyle servis edilen lezzetli, bitki bazlı bir köfte.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://traislowenda.com/img/7.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Devre Arası İkramları
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Taslak Seçimleri: Takımınızın zaferlerine kadeh kaldırmak için mükemmel bir yerel ve ithal bira seçkisi.
							<br />
							<br />
							Sporcu Kokteyli: Oyun boyunca moralinizi yüksek tutmak için ferahlatıcı bir karışım olan özel kokteylimiz.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});